import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Heading, HStack } from '@chakra-ui/react';
import { Dropdown } from '@frontend/design-system/components/Toolbar/Dropdown';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { appColors, appValues } from '../config/constants';
import { getPageUrl } from '../config/paths';
import { FeatureFlags, useFeatureFlag } from '../store/featureFlag';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { fetchOrganizationSnapshots, fetchOrgById, selectAllOrgsSnapshots, selectOrgsCreateMode, } from '../store/orgSlice';
import { selectUserData } from '../store/userDataSlice';
export const PageHeader = ({ title, hideOrgsDropdown, }) => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const orgs = useAppSelector(selectAllOrgsSnapshots);
    const createMode = useAppSelector(selectOrgsCreateMode);
    const userCurrent = useAppSelector(selectUserData);
    const universalHeaderFeatureFlag = useFeatureFlag(FeatureFlags.FF_UNIVERSAL_HEADER);
    useEffect(() => {
        dispatch(fetchOrganizationSnapshots());
    }, [userCurrent]);
    useEffect(() => {
        if (!createMode &&
            !window.location.href.includes('/organizationDetails/create') &&
            universalHeaderFeatureFlag &&
            hasOrgsToRenderDropdown) {
            if (params.oid && orgs.find((org) => org.id === params.oid)) {
                dispatch(fetchOrgById(params.oid));
            }
            else if (params.oid) {
                navigate(getPageUrl(orgs[0].id, '/'));
            }
            else if (!params.oid) {
                navigate(getPageUrl(orgs[0].id, location.pathname));
            }
        }
    }, [params.oid, orgs]);
    const handleOrgChange = (e) => {
        const orgId = e;
        const url = getPageUrl(orgId, location.pathname, true);
        navigate(url);
    };
    const showDropdown = universalHeaderFeatureFlag && !hideOrgsDropdown;
    const hasOrgsToRenderDropdown = showDropdown && orgs && orgs.length > 0;
    return (_jsx(_Fragment, { children: _jsx(Flex, { as: 'header', h: `${appValues.HEADER_HEIGHT}px`, w: '100%', alignItems: 'center', children: _jsxs(HStack, { justifyContent: 'space-between', w: '100%', h: '100%', py: '5px', children: [_jsx(Heading, { as: 'h1', fontSize: 36, fontWeight: 400, color: appColors.TEXT_COLOR, whiteSpace: 'nowrap', children: title }), hasOrgsToRenderDropdown && (_jsx(Dropdown, { dropdownValues: orgs.map((org) => ({
                            label: org.name,
                            value: org.id,
                        })) || [], label: 'Organization', isLoading: orgs === undefined, handleChange: handleOrgChange, value: params.oid || '', bgColor: appColors.PRIM_BLUE }))] }) }) }));
};
