import { faker } from '@faker-js/faker';
export class OrganizationSummaryResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ organizationId: faker.lorem.slug(1), organizationIdentity: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), name: faker.lorem.slug(1), smallLogoUrl: null, largeLogoUrl: null, verticalMarket: null, businessUnit: faker.lorem.slug(1), stateOrProvince: faker.lorem.slug(1), clientConcierge: null, userCount: faker.number.int({ min: undefined, max: undefined }) }, this.overrides);
        };
        this.overrides = overrides;
    }
}
